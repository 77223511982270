import Navbottom from './components/bottom-nav/Bottom-nav';
import Navbar from './components/navbar/Navbar';
import Logo from './components/logo/Logo';
import Hero from './components/hero-section/Hero-Section';
import Services from './components/services/Services';
import Carousel from './components/logo-carousel/Logocarousel';
import Stages from './components/Stages/Stages';
import Projets from './components/projets/Projets';
import Contact from './components/contact/Contact';
import Questions from './components/questions/Questions';
import Footer from './components/footer/Footer';


import './App.css';

function App() {
  return (
    <div className="App">
      <Navbottom/>
      <Navbar/>
      <Logo/>
      <Hero/> 
      <Services/>
      <Carousel/>
      <Stages/>
      <Projets/>
      <Contact/>
      <Questions/>
      <Footer/>
    </div>
  );
}

export default App;
